import React from 'react';
import { Helmet } from 'react-helmet'; // Import Helmet for schema markup
import './header.css';
import color from '../../assets/color.png';
import house from '../../assets/house.png';

const Header = () => {
  return (
    <div className="gpt3__header section__padding" id="home">
      <Helmet>
        <script type="application/ld+json">
          {`
          {
              "@context": "https://schema.org",
              "@type": "Organization",
              "name": "Your Website Name",
              "url": "https://www.yourwebsite.com",
              "logo": "https://www.yourwebsite.com/logo.png"
          }
          `}
        </script>
      </Helmet>
      
      <div className="gpt3__header-content">
        <h1 className="gradient__text">PASS is indeed prevalent in Bangladesh's northern region</h1>
        <p>The village's residents, who have few means, may be able to survive if they are helped by an amiable neighborhood. A few sturdy families for an industrialized nation.</p>
        <div className="gpt3__header-content__input">
          {/* You can add input elements here if needed */}
        </div>
        <div className="gpt3__header-content__color">
          <img src={color} alt="color" />
          <p>Initiative towards a better result</p>
        </div>
      </div>
      <div className="gpt3__header-image">
        <img src={house} alt="house" />
      </div>
    </div>
  );
}

export default Header;